import React from "react";

const StepIndicator = ({ currentStep }) => (
  <ul id="progressbar" className="font-sans">
    <li className={currentStep >= 0 ? "active" : ""}>Personal Information</li>
    <li className={currentStep >= 1 ? "active" : ""}>Business Information</li>
    <li className={currentStep >= 2 ? "active" : ""}>General Information</li>
  </ul>
);

export default StepIndicator;
