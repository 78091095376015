import React, {useRef} from "react";
import ziad from "../assets/ziad.png";
import abiodun from "../assets/babatunde.png";
// import adeshola from "../assets/adeshola.png";
import bukola from "../assets/bukola smith.png";
import oluyomi from "../assets/oluyomi.png";
import oyeyimka from "../assets/oyeyimka.png";
import henry from "../assets/henry.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Speakers = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.from(ref1.current, {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref1.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(ref2.current, {
      x: 100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref2.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(ref3.current, {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref3.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(ref4.current, {
      x: 100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref4.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(ref5.current, {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref5.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
    gsap.from(ref6.current, {
      x: 100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      scrollTrigger: {
        trigger: ref6.current,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
  }
  , []);


  return (
    <div className="container px-4 md:px-8 py-5 flex flex-col min-h-screen justify-center items-center about">
      <div className="flex flex-col gap-3 z-10 justify-center items-center">
        <h1 className="text-5xl text-center md:text-left md:text-6xl font-nueu text-primary-color ">
          Our Speakers
        </h1>
        <p className="text-base text-secondary-color leading-loose w-full md:w-4/6 text-center ">
          Our lineup of esteemed speakers brings a wealth of expertise in
          corporate strategy, people management, and commercial management.
          Learn from the best and elevate your business acumen.
        </p>
      </div>
      <div className="z-10 flex-1 mt-14">
        <div className="flex flex-col md:flex-row gap-5 justify-center items-center w-full md:w-4/5 mx-auto mt-8">
          
          <div ref={ref1} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={ziad}
                alt=" Ziad Maalouf "
                className="w-full h-full md:w-full md:h-full rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color  ">
                Ziad Maalouf
              </h1>
              <p className="text-[12px] text-secondary-color text-center">
                MD, Seven-up Bottling Company
              </p>
            </div>
          </div>

          <div ref={ref2} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={abiodun}
                alt=" babatunde irukera"
                className="w-full h-full md:w-full md:h-full object-fill  rounded-full"
              />
            </div>
            <div className="  flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color ">
                Babatunde Irukera
              </h1>
              <p className="w-[250px] text-[12px] text-secondary-color text-center">
                Former CEO, Federal Competition & Consumer Protection Commission
              </p>
            </div>
          </div>
          <div ref={ref3} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={bukola}
                alt=" Bukola Smith"
                className="w-full h-full md:w-full md:h-full rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color ">
                Bukola Smith
              </h1>
              <p className="text-[12px] text-secondary-color text-center">
                CEO, FSDH Merchant Bank
              </p>
            </div>
          </div>

          <div ref={ref4} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={oluyomi}
                alt="oluyomi banjo"
                className="w-full h-full md:w-full md:h-full rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color ">
                Oluyomi Banjo
              </h1>
              <p className="text-[12px] text-secondary-color text-center">
                Programme Coordinator, UNIDO
              </p>
            </div>
          </div>

          <div ref={ref5} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={oyeyimka}
                alt="oyeyimka adeboye"
                className="w-full h-52 md:w-full md:h-full object-cover rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color ">
              Oyeyimika Adeboye
              </h1>
              <p className="text-[12px] text-secondary-color text-center">
              CEO/M.D, Cadbury Nigeria Plc
              </p>
            </div>
          </div>
          <div ref={ref6} className="flex flex-col gap-4 justify-center items-center w-full md:w-1/2">
            <div className="flex justify-center items-center w-64 h-56 md:w-52 md:h-52 bg-gradient-to-br from-secondary-color to-primary-color rounded-full">
              <img
                src={henry}
                alt="henry oroh"
                className="w-full h-52 md:w-full md:h-full object-cover rounded-full"
              />
            </div>
            <div className="flex flex-col gap-2 justify-center items-center">
              <h1 className="text-2xl font-nueu text-secondary-color ">
              Henry Oroh
              </h1>
              <p className="text-[12px] text-secondary-color text-center">
              Executive Director, Zenith Bank Plc
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Speakers;
