import React from "react";
import AccordionItem from "./AccordionItem";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Faq = () => {
  const faqData = [
    {
      question: "What is this program about?",
      answer:
        "Ultimately, we aspire to cultivate a community of forward-thinking individuals who will drive positive change in their respective fields. Through this initiative, we hope to contribute to the growth of a highly skilled workforce and promote innovation that positively impacts our society.",
    },
    {
      question: "How can I apply for the program?",
      answer:
        'To apply for the program, please click on the "Register Now" button. Follow the instructions provided, complete the application form. Be sure to check the application deadline and ensure all information is accurate.',
    },
    {
      question:
        " Is there a registration fee for participating in the program?",
      answer:
        "Yes, there is a registration for the respective session types (Virtual and Physical). This fee is non-refundable and must be paid upon submission of your application. Payment instructions will be provided upon completion of the application form. Please note that your application will not be considered until payment is received.",
    },

    {
      question: "When will I be notified about the status of my application?",
      answer:
        "You will be notified about the status of your application upon confirmation of payment. Please ensure that all contact information provided is accurate to avoid any delays in communication.",
    },
    {
      question: "Can I edit my submitted application after it has been sent?",
      answer:
        "No, you cannot edit your application after it has been submitted. Please ensure that all information is accurate before submitting your application.",
    }
   
  ];

  const ref1 = faqData.map(() => React.createRef());

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    ref1.forEach((ref, index) => {
      gsap.from(ref.current, {
        x: -100,
        opacity: 0,
        duration: 1,
        ease: "in-out",
        delay: index * 0.2, // delay each item by 0.2 seconds
        scrollTrigger: {
          trigger: ref.current,
          start: "top 80%",
          end: "top 50%",
          toggleActions: "play none none reverse",
        },
      });
    });
  }, []);

  return (
    <div className=" w-full md:w-4/5 flex flex-col md:text-center mx-auto md:mt-0 mt-8 bg-slate-50 mb-4 py-4 h-full ">
      <h2 className="text-4xl font-nueu text-secondary-color md:mx-0 mx-4 mb-6">
        Frequently Asked Questions
      </h2>
      <div className="space-y-8">
        {faqData.map((faq, index) => (
          <div ref={ref1[index]} key={index}>
            <AccordionItem question={faq.question} answer={faq.answer} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
