import picOne from "./assets/ziad.png";
import picTwo from "./assets/babatunde.png";
import picFour from "./assets/oluyomi.png";
import picFive from "./assets/segun.png";
import picSix from "./assets/ayodele.png";
import picEight from "./assets/dominion.png";
import picNine from "./assets/oyeyimka.png";
import picTen from "./assets/emeka.png";
import picEleven from "./assets/bukola smith.png";
import picTwelve from "./assets/henry.png";

export const data = [
 
  {
    name: "Babatunde Irukera",
    position: "Former CEO Federal Competition & Consumer Protection Commission",
    img: picTwo,
  },
  {
    name: "Bukola Smith",
    position: "CEO, FSDH Merchant Bank",
    img: picEleven,
  },
  {
    name: "Ziad Maalouf",
    position: "Managing Director, 7Up Bottling Company",
    img: picOne,
  },
  {
    name: "Oyeyimika Adeboye",
    position: "CEO/M.D, Cadbury Nigeria Plc",
    img: picNine,
  },
  {
    name: "Henry Oroh",
    position: "Executive Director, Zenith Bank Plc",
    img: picTwelve,
  },
  {
    name: "oluyomi banjo",
    position: "National programme coordinator, environment and energy, UNIDO",
    img: picFour,
  },
 
  {
    name: "ayodele ojosipe",
    position: "Head, Business Development And Relationship MGT, DBN",
    img: picSix,
  },
  
 
  {
    name: "segun ogunleye",
    position: "General Manager marketing, 7Up Bottling Company",
    img: picFive,
  },
  {
    name: "Dominion Izedonmi",
    position: "GM Business Optimization, 7Up Bottling Company",
    img: picEight,
  },
  
  {
    name: "Emeka Anowai",
    position: "National Sales Manager HPC, 7Up Bottling Company",
    img: picTen,
  },

 
];
