import React, { useState, useEffect } from "react";
import LoginForm from "../components/LoginForm";
import Loading from "../components/Loading";
import ValidatedModal from "../components/ValidatedModal";
import InitialModal from "../components/InitialModal";
import { baseUrl } from "../baseUrl";
import axios from "axios";

const Qrcode = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const [isValid, setIsValid] = useState(false);

  const guid = window.location.pathname.split("/").pop();
  
 // HANDLE LOGIN
const handleLogin = async (username, password) => {
  setLoading(true);
  try {
    const response = await axios.post(`${baseUrl}Auth/Login`, {
      username,
      password,
    });
    
    if (response.status === 200) {
      const token = response.data;
      localStorage.setItem("token", token);

      const expiryTime = new Date().getTime() + 12 * 60 * 60 * 1000;
      localStorage.setItem("expiryTime", expiryTime);
      localStorage.setItem("isLoggedIn", true);
      setIsLoggedIn(true);
      setShowLogin(false);
      setLoading(false);
    }
  } catch (error) {
    console.error(error);
  }
};

// CHECK IF TOKEN HAS EXPIRED
useEffect(() => {
  const token = localStorage.getItem("token");
  const expiryTime = localStorage.getItem("expiryTime");

  if (new Date().getTime() > expiryTime) {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryTime");
    localStorage.removeItem("isLoggedIn");
  } else {
    setIsLoggedIn(localStorage.getItem("isLoggedIn") === "true");
    setLoading(false);
  }
}, []);

// HANDLE QR CODE VALIDITY CHECK
useEffect(() => {
  const checkValidity = async () => {
    try {
      const token = localStorage.getItem("token"); // Get the token from local storage
      const headers = token ? { 'Authorization': `Bearer ${token}` } : {}; // If token exists, include it in headers

      const response = await axios.get(`${baseUrl}Registration/GetAdmissionDetails/${guid}`, {
        headers: headers
      });

      setIsValid(response.data.valid);
      setData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  checkValidity();
}, [guid, isLoggedIn]);


  // LOG SCAN
  useEffect(() => {
    const logScan = async () => {
      try {
        const ipResponse  = await axios.get("https://api.ipify.org?format=json");
        const ipAddress = ipResponse.data.ip;

        const clientDeviceName = navigator.userAgent;

        await axios.post(`${baseUrl}Registration/CreateScanLog`, {
          reference: guid,
          ipAddress,
          clientDeviceName,
        });
      } catch (error) {
        console.error(error);
      }
    };
    logScan();
  }, []);

  

  const handleShowLogin = () => {
    setShowLogin(true);
  };

  const handleCLoseLogin = () => {
    setShowLogin(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryTime");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("sessionId");
    setIsLoggedIn(false);
  };

  const onSubmit = (data) => {
    handleLogin(data.username, data.password);
    console.log(data);
    handleCLoseLogin();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-full h-screen flex justify-center items-center qr-container2 relative ">
      <div className="h-[90vh] w-4/5 mx-auto flex flex-col gap-4 relative  qr-bg">
        {!isLoggedIn && <InitialModal data={data} handleShowLogin={handleShowLogin} />}
        {isLoggedIn && <ValidatedModal 
          data={data}
          handleShowLogin={handleShowLogin} 
          handleLogout={handleLogout}
        />}
        {/* {!isValid && <InvalidModal data={data} handleShowLogin={handleShowLogin}  isLoggedIn={isLoggedIn} />} */}
      </div>

      {showLogin && (
        <LoginForm handleClose={handleCLoseLogin} onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default Qrcode;
