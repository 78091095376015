import React, { useEffect } from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Speakers from "../components/Speakers";
import Faq from "../components/Faq";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Main = () => {
  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px",
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible");
    }
  }, [animation, inView]);

  const boxVariants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        ease: "easeOut",
      },
    },
  };

  return (
    <div>
      <Navbar />
      <motion.div
        className="box"
        variants={boxVariants}
        initial="hidden"
        animate="visible"
      >
        <section id="hero">
          <Hero />
        </section>
      </motion.div>

      <motion.div
        className="box"
        variants={boxVariants}
        initial="hidden"
        animate="visible"
      >
        <section id="about">
          <About />
        </section>
      </motion.div>

      <motion.div
        className="box"
        variants={boxVariants}
        initial="hidden"
        animate="visible"
        ref={contentRef}
      >
        <section id="features">
          <Features />
        </section>
      </motion.div>
      



      <motion.div
        className="box"
        variants={boxVariants}
        initial="hidden"
        animate="visible"
        ref={contentRef}
      >
        <section id="speakers">
          <Speakers />
        </section>
      </motion.div>
     
      <section id="faqs" className="">
        <Faq />
      </section>

      <section id="footer">
        <Footer />
      </section>
    </div>
  );
};

export default Main;
