import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
const LoginForm = ({ handleClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-4 z-[999]">
      <div className="bg-white p-4 rounded-lg w-full md:w-2/5 h-auto py-2">
        <div className="flex justify-end">
          <button
            className=" w-10 h-10 border mt-2 p-2 border-gray-300 text-gray-500 rounded-full hover:bg-gray-100 flex justify-center items-center"
            onClick={handleClose}
          >
            <IoClose className="w-full h-full" />
          </button>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 mt-3 px-3 md:px-5 py-4"
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="username" className="text-sm">
              Username
            </label>
            <input
              type="text"
              className="border border-gray-300 p-2 rounded"
              {...register("username", { required: "Username is required" })}
            />
            {errors.username && (
              <p className="text-red-500 text-sm ">{errors.username.message}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="password" className="text-sm">
              Password
            </label>
            <div className="flex w-full justify-between items-center border border-gray-300 p-2 rounded">
              <input
                 type={showPassword ? "text" : "password"}
                className=" w-full outline-none focus:outline-none"
                {...register("password", { required: "Password is required" })}
              />
              <button type="button" onClick={togglePasswordVisibility}>
                {showPassword ? <LuEyeOff /> : <LuEye />}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm ">{errors.password.message}</p>
            )}
          </div>
          <button
            className="bg-primary-color text-white py-3 text-lg rounded mt-5"
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
