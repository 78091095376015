import React from "react";
import logo from "../assets/sme-logo.png";
import { BiLogOut } from "react-icons/bi";
import moment from "moment";
const ValidatedModal = ({ data, handleLogout }) => {
  console.log(data);
  if (
    !data.firstName &&
    !data.lastName &&
    !data.sessionName &&
    !data.sessionLocation &&
    !data.seatNumber &&
    data.sessionId === 0 &&
    data.sessionDate === "0001-01-01T00:00:00" &&
    !data.paymentReceived &&
    data.admissionId === 0 &&
    data.admissionDate === "0001-01-01T00:00:00" &&
    !data.justAdmitted &&
    !data.valid &&
    !data.message
  ) {
    return (
      <div className="h-full flex justify-center items-center">
        <h1 className="text-3xl">Invalid Admission Ticket</h1>
      </div>
    );
  }
  return (
    <>
      <div className="p-3 h-[10vh] w-full justify-between">
        <img src={logo} alt="logo" className="w-[70px] h-[50px]" />
        <button
          className="absolute top-3 right-3 bg-primary-color hover:bg-green-400 text-white text-lg font-bold py-1 px-2 rounded"
          onClick={handleLogout}
        >
          <BiLogOut />
        </button>
      </div>
      <div className="flex min-h-screen justify-center">
        <div className="relative flex flex-col mt-5 items-center">
          {/* <svg
          viewBox="0 0 26 26"
          xmlns="http://www.w3.org/2000/svg"
          className="checkmark2 border-2 border-primary-color rounded-full p-2"
        >
          <g
            stroke="currentColor"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              className="circle"
              d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
            />
            <path className="tick" d="M6.5 13.5L10 17 l8.808621-8.308621" />
          </g>
        </svg> */}
          <h1 className="text-secondary-color font-nueu text-2xl mt-4 md:mt-2 md:text-3xl">
            Admission Successful
          </h1>
          <div className="w-full py-4 md:py-8 md:px-6 px-2 flex flex-col gap-4 mt-4 border-dashed border-2 rounded-md border-primary-yellow ">
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Name:</span>{" "}
              {data.firstName} {data.lastName}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Session:</span>{" "}
              {data.sessionName}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Location:</span>{" "}
              {data.sessionLocation}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Date:</span>{" "}
              {moment(data.sessionDate).format("Do MMMM YYYY")}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Time:</span>{" "}
              {moment(data.sessionDate).format("hh:mm A")}
            </p>
            {/*  seat number */}
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu text-lg">Seat Number:</span>{" "}
              {data.seatNumber}
            </p>
          </div>
          {data?.valid === false && data?.justAdmitted === true && (
            <p className="text-sm md:text-base text-slate-700 px-2 bg-white py-3 ">
             {data?.message}
            </p>
          )}
          <p className="absolute bottom-0 text-sm md:text-sm mt-4 text-slate-600">
            Thank you!
          </p>
        </div>
      </div>
    </>
  );
};

export default ValidatedModal;
