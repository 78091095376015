import React, { useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Features = () => {

  const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    useGSAP(() => {
      gsap.registerPlugin(ScrollTrigger);
      gsap.from(ref1.current, {
          x: -100,
          opacity: 0,
          duration: 1,
          ease: "in-out",
          scrollTrigger: {
              trigger: ref1.current,
              start: "top 80%",
              end: "top 50%",
              toggleActions: "play none none reverse",
          },
      });
      gsap.from(ref2.current, {
          x: 100,
          opacity: 0,
          duration: 1,
          ease: "in-out",
          scrollTrigger: {
              trigger: ref2.current,
              start: "top 80%",
              end: "top 50%",
              toggleActions: "play none none reverse",
          },
      });
      gsap.from(ref3.current, {
          x: -100,
          opacity: 0,
          duration: 1,
          ease: "in-out",
          scrollTrigger: {
              trigger: ref3.current,
              start: "top 80%",
              end: "top 50%",
              toggleActions: "play none none reverse",
          },
      });
      gsap.from(ref4.current, {
          x: 100,
          opacity: 0,
          duration: 1,
          ease: "in-out",
          scrollTrigger: {
              trigger: ref4.current,
              start: "top 80%",
              end: "top 50%",
              toggleActions: "play none none reverse",
          },
      });
      gsap.from(ref5.current, {
          x: -100,
          opacity: 0,
          duration: 1,
          ease: "in-out",
          scrollTrigger: {
              trigger: ref5.current,
              start: "top 80%",
              end: "top 50%",
              toggleActions: "play none none reverse",
          },
      });
    }, []);



  return (
    <div
      className="bg-primary-color bg-opacity-10 container px-3 md:px-8 py-5 flex flex-col min-h-screen justify-center items-center features"
      id="features"
    >
      <div className="w-full md:w-4/5 px-4 md:px-8 flex flex-col z-10 mt-8">
        <h1 className="text-5xl text-center md:text-left md:text-6xl font-nueu text-primary-color ">
          Exciting Features of the Program
        </h1>
        <div className="w-full md:w-11/12">
          <div ref={ref1} className="w-full flex flex-col md:flex-row md:justify-end mt-6 md:mt-0">
            <div className="flex flex-col md:flex-row gap-8 justify-start items-start md:justify-center md:items-center w-full md:w-[500px] h-fit md:h-[250px] ">
              <div className="flex justify-center items-center font-nueu ">
                <h1 className="text-5xl md:text-8xl font-nueu text-primary-color ">
                  01.
                </h1>
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-4xl font-nueu text-secondary-color ">
                  Loans
                </h1>
                <p className="text-base  text-secondary-color leading-loose ">
                  Giving loans to 5 participants with ideas that have the
                  potential to scale up.
                </p>
              </div>
            </div>
          </div>
          <div ref={ref2} className="w-full flex flex-col md:flex-row  md:justify-start mt-4 md:mt-0">
            <div className="flex flex-col md:flex-row gap-8 justify-end items-end md:justify-center md:items-center w-full md:w-[500px] h-fit md:h-[250px] ">
              <div className="flex justify-center items-center font-nueu ">
                <h1 className="text-5xl md:text-8xl font-nueu text-primary-color ">
                  02.
                </h1>
              </div>
              <div className="flex flex-col gap-2 justify-end items-end md:justify-start md:items-start ">
                <h1 className="text-4xl font-nueu text-secondary-color ">
                  Subsidized Loans
                </h1>
                <p className="text-base  text-secondary-color leading-loose text-right md:text-left ">
                  Facilitating subsidized loans (through First Bank) to 5
                  participants with ideas that can scale up.
                </p>
              </div>
            </div>
          </div>
          <div ref={ref3} className="w-full flex flex-col md:flex-row md:justify-end mt-4 md:mt-0">
            <div className="flex flex-col md:flex-row gap-8 justify-start items-start md:justify-center md:items-center  w-full md:w-[500px] h-fit md:h-[250px] ">
              <div className="flex justify-center items-center font-nueu ">
                <h1 className="text-5xl md:text-8xl font-nueu text-primary-color ">
                  03.
                </h1>
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-4xl font-nueu text-secondary-color ">
                  Grants
                </h1>
                <p className="text-base  text-secondary-color leading-loose ">
                  Giving grants of &#8358;5 million each to 3 of the best
                  participants.
                </p>
              </div>
            </div>
          </div>
          <div ref={ref4} className="w-full flex flex-col md:flex-row  md:justify-start mt-4 md:mt-0">
            <div className="flex flex-col md:flex-row gap-8 justify-end items-end md:justify-center md:items-center w-full md:w-[500px] h-fit md:h-[250px] ">
              <div className="flex justify-center items-center font-nueu ">
                <h1 className="text-5xl md:text-8xl font-nueu text-primary-color ">
                  04.
                </h1>
              </div>
              <div className="flex flex-col gap-2 justify-end items-end md:justify-start md:items-start ">
                <h1 className="text-4xl font-nueu text-secondary-color ">
                  Strategic Growth
                </h1>
                <p className="text-base  text-secondary-color leading-loose text-right md:text-left ">
                  Placing 3-5 environmental friendly participants ideas on GCIP
                  Acceleration Project.
                </p>
              </div>
            </div>
          </div>
          <div ref={ref5} className="w-full flex flex-col md:flex-row md:justify-end mt-6 md:mt-0">
            <div className="flex flex-col md:flex-row gap-8 justify-start items-start md:justify-center md:items-center w-full md:w-[500px] h-fit md:h-[250px] ">
              <div className="flex justify-center items-center font-nueu ">
                <h1 className="text-5xl md:text-8xl font-nueu text-primary-color ">
                  05.
                </h1>
              </div>
              <div className="flex flex-col gap-2">
                <h1 className="text-4xl font-nueu text-secondary-color ">
                  Leadership Development
                </h1>
                <p className="text-base  text-secondary-color leading-loose ">
                  Giving 360 Leadership assessment with 30+ pages of personal
                  leadership development plan to 5 participants.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
