import React, { useState, useEffect } from 'react';

const CountdownTimer = ({sessionDate}) => {
  // console.log(sessionDate); 
  const calculateTimeLeft = () => {

    let date = new Date(sessionDate);
    const difference = +date - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="text-white text-center text-xl">
      <div className="flex justify-center items-center gap-2 p-4 font-nueu mt-2">
        <div className='bg-gradient-to-b from-primary-color to-secondary-color
       w-20 h-20  md:w-24 md:h-24 flex flex-col justify-center items-center rounded-lg'>
          <h1 className="text-4xl ">{timeLeft.days}</h1>

          <p>Days</p>
        </div>
        <div className='bg-gradient-to-b from-primary-color to-secondary-color  w-20 h-20  md:w-24 md:h-24 flex flex-col justify-center items-center rounded-lg'>
          <h1  className="text-4xl ">{timeLeft.hours}</h1>
          <p>Hours</p>
        </div>
        <div className='bg-gradient-to-b from-primary-color to-secondary-color  w-20 h-20  md:w-24 md:h-24 flex flex-col justify-center items-center rounded-lg'>
          <h1  className="text-4xl ">{timeLeft.minutes}</h1>
          <p>Minutes</p>
        </div>
        <div className='bg-gradient-to-b from-primary-color to-secondary-color  w-20 h-20  md:w-24 md:h-24 flex flex-col justify-center items-center rounded-lg'>
          <h1  className="text-4xl ">{timeLeft.seconds}</h1>
          <p>Seconds</p>
        </div>
    </div>
    </div>
  );
};

export default CountdownTimer;