import React from "react";

const Loading = () => {
  return (
    <div className="container4">
      <div className="tree">
        <div className="branch" style={{ '--x': 0 }}>
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
        <div className="branch" style={{ '--x': 1 }}>
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
        <div className="branch" style={{ '--x': 2 }}>
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
        <div className="branch" style={{ '--x': 3 }}>
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
        <div className="stem">
          <span style={{ '--i': 0 }}></span>
          <span style={{ '--i': 1 }}></span>
          <span style={{ '--i': 2 }}></span>
          <span style={{ '--i': 3 }}></span>
        </div>
        <span className="shadow"></span>
      </div>
      <div className="text mt-12 text-lg">Loading...</div>
    </div>
  );
};

export default Loading;
