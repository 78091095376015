import React from "react";

import firstBank from "../assets/firstbank.png";
import sbc from "../assets/sbclogo.png";
import unido from "../assets/unido.png";
import fsdh from "../assets/fsdh.png";
import mondelez from "../assets/mondelez.png";
import dbn from "../assets/dbn-logo.png";
import rite from "../assets/rite.png";
import zenith from "../assets/zenith.svg";
const Footer = () => {
  const year = new Date().getFullYear();
  const partners = [firstBank, sbc,  mondelez,  rite, zenith];
  return (
    <footer className="w-full bg-primary-color text-slate-100 py-8 text-center">
      <div className="flex w-full justify-center">
        {partners.map((partner, index) => (
          <img
            key={index}
            src={partner}
            alt="partner"
            className="h-10 w-10 mx-4 my-2 object-contain"
          />
        ))}
      </div>
      <div className="container mx-auto text-sm">
        <p>&copy; {year} The SME Scale-Up. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
