import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import img1 from "../assets/img2.jpg";
import img2 from "../assets/img7.jpg";
import img3 from "../assets/img3.jpg";
import img4 from "../assets/img4.jpg";
import img5 from "../assets/img5.jpg";
import img6 from "../assets/img6.jpg";
import img7 from "../assets/img1.jpg";
import img8 from "../assets/img8.jpg";
import img9 from "../assets/img9.jpg";
import img10 from "../assets/img10.jpg";
import img11 from "../assets/img11.jpg";
import img12 from "../assets/img12.jpg";
import img13 from "../assets/img13.jpg";
import img14 from "../assets/img14.jpg";
import img15 from "../assets/img15.jpg";
import img16 from "../assets/img16.jpg";
import img17 from "../assets/img17.jpg";
import img18 from "../assets/img18.jpg";
import img19 from "../assets/img19.jpg";
import img20 from "../assets/img20.jpg";
import { motion, AnimatePresence } from "framer-motion";
import scaleupvid from "../assets/scaleup-vid.mp4";
import smeAudio from "../assets/sme-audio.wav";
import { ImPrevious, ImNext } from "react-icons/im";
import {
  MdPlayCircleOutline,
  MdOutlinePauseCircleOutline,
  MdOutlineFullscreen,
} from "react-icons/md";

const Gallery = () => {
  const images = useMemo(
    () => [
      img1,
      img2,
      img3,
      img4,
      img5,
      img6,
      img7,
      img8,
      img9,
      img10,
      img11,
      img12,
      img13,
      img14,
      img15,
      img16,
      img17,
      img18,
      img19,
      img20,
    ],
    []
  );
  const slideVariants = {
    hiddenRight: {
      x: "100%",
      opacity: 0,
    },
    hiddenLeft: {
      x: "-100%",
      opacity: 0,
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3,
      },
    },
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSlideshowPlaying, setIsSlideshowPlaying] = useState(false);

  const toggleSlideshow = () => {
    setIsSlideshowPlaying(!isSlideshowPlaying);
  };

  const handleNavigation = useCallback(
    (direction) => {
      const newIndex =
        direction === "next"
          ? (currentIndex + 1) % images.length
          : (currentIndex - 1 + images.length) % images.length;
      setCurrentIndex(newIndex);
    },
    [currentIndex, images.length]
  );

  useEffect(() => {
    let timer;
    if (isSlideshowPlaying) {
      timer = setInterval(() => {
        handleNavigation("next");
      }, 3000); // Change image every 3 seconds
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer); // Clean up on unmount
  }, [isSlideshowPlaying, handleNavigation]);

  const slideShowRef = useRef(null);

  const openFullscreen = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      if (isSlideshowPlaying) {
        audioRef.current.play();
        audioRef.current.volume = 0.1
        
      } else {
        audioRef.current.pause();
      }
    }
  }, [isSlideshowPlaying]);

  return (
    <div className="w-full relative min-h-screen flex flex-col justify-between bg-gray-100">
      <Navbar />
      <div className="absolute top-0 left-0 w-full h-screen">
        <video
          src={scaleupvid}
          autoPlay
          muted
          controls
          className="w-full h-full object-cover"
        ></video>
      </div>
      <div className="w-full h-screen"></div>
      <div className="w-full relative  h-full mt-8 flex flex-col gap-2 items-center justify-center px-2 md:px-5">
        <motion.h1
          className="text-2xl md:text-4xl font-bold text-center my-4 text-gray-800"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
        >
          Discover captivating moments in our gallery
        </motion.h1>
        <div
          className="w-full h-full container-grid mb-10 bg-black"
          ref={slideShowRef}
        >
          <audio 
          ref={audioRef}
          src={smeAudio} autoPlay loop
        
          ></audio>
          <MdOutlineFullscreen
            onClick={() => openFullscreen(slideShowRef.current)}
            className="absolute top-0 right-0 text-white text-2xl m-4 cursor-pointer"
          />

          <AnimatePresence mode="wait">
            <motion.img
              src={images[currentIndex]}
              alt={`img-${currentIndex}`}
              className="w-full h-full object-cover rounded-lg"
              key={currentIndex}
              variants={slideVariants}
              // initial="hidden"
              initial={{ x: 300, opacity: 0 }}
              exit={{ x: -300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>
          <div className="absolute top-0 left-0 flex justify-between w-full">
            <button
              onClick={() => handleNavigation("prev")}
              disabled={isSlideshowPlaying}
            >
              <ImPrevious />
            </button>
            <button onClick={toggleSlideshow}>
              {isSlideshowPlaying ? (
                <MdOutlinePauseCircleOutline />
              ) : (
                <MdPlayCircleOutline />
              )}
            </button>
            <button
              onClick={() => handleNavigation("next")}
              disabled={isSlideshowPlaying}
            >
              <ImNext />
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
