import React, { useState } from 'react';

const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggleAccordion}
      >
        <h3 className="text-base md:text-lg font-semibold">{question}</h3>
        <span className={`transform ${isOpen ? 'rotate-180' : 'rotate-0'} transition-transform duration-300`}>&#9660;</span>
      </div>
      {isOpen && (
        <div className="p-4 text-left leading-loose">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
