import React from "react";

const Modal = ({ title, children, onConfirm, onCancel, confirmText = "Confirm", cancelText = "Cancel", loading = false }) => {
  return (
    <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="modal-content bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
        <h2 className="text-lg font-bold">{title}</h2>
        <div className="modal-body">{children}</div>
        <div className="modal-actions flex justify-end gap-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            {cancelText}
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-primary-color text-white rounded hover:bg-opacity-90"
            disabled={loading}
          >
            {loading ? "Processing..." : confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
