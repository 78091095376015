import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Main from './page/Main';
import Form from './page/Form';
import Sessions from './page/Sessions';
import SingleSession from './page/SingleSession';
import { Routes, Route } from 'react-router-dom';
import Gallery from './page/Gallery';
import Notfound from './page/Notfound';
import Success from './components/Success';
import Qrcode from './page/Qrcode';
const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <main className="container md:px-0 bg-primary-color bg-opacity-10 overflow-clip">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="register/:id" element={<Form />} />
        <Route path="sessions" element={<Sessions />} />
        <Route path="session/:id" element={<SingleSession />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path=":id" element={<Main />} />
        <Route path="success" element={<Success />} />
        <Route path="a/:id" element={<Qrcode />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </main>
  );
};

export default App;