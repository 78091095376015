import React, {createContext, useState, useEffect} from "react";
import { baseUrl } from "./baseUrl";
import Loading from "./components/Loading";
const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [postResponse, setPostResponse] = useState(null);
  const url = `${baseUrl}Registration/GetFormData`;
  const sessionUrl = `${baseUrl}Registration/GetSessions`;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response1 = await fetch(url);
        const response2 = await fetch(sessionUrl);
        const result = await response1.json();
        const result2 = await response2.json();
        setData(result);
        setSessions(result2); 
      } catch (error) {
        let errorMessage = 'An error occurred while fetching sessions';

        if (error.message.includes('404')) {
          errorMessage = 'Data not found';
        } else if (error.message.includes('500')) {
          errorMessage = 'Server error. Please try again later';
        }
  
        setError(errorMessage);
      }
      setLoading(false);
    };
    fetchData();
  }, [url, sessionUrl]);

  if (loading) {
    return <Loading />;
  }
  // if (error) {
  //   return (
  //     <FormContext.Provider value={{ error }}>
  //       <p>Error: {error.message}</p>
  //     </FormContext.Provider>
  //   );
  // }



  return (
    <FormContext.Provider value={{ formData, setFormData, data, sessions, postResponse, setPostResponse, error }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
