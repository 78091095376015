import React, {useRef} from "react";
import logo2 from "../assets/unido.png";
import logo3 from "../assets/firstbank.png";
import logo1 from "../assets/sme-logo.png";
import { Link } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Hero = () => {

 const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);
    const element1 = ref1.current;
    const element2 = ref2.current;
    const element3 = ref3.current;
    const element4 = ref4.current;
    const element5 = ref5.current;

    gsap.from([element1, element2, element3, element4, element5], {
      x: -100,
      opacity: 0,
      duration: 1,
      ease: "in-out",
      stagger: 0.2,
      scrollTrigger: {
        trigger: element1,
        start: "top 80%",
        end: "top 50%",
        toggleActions: "play none none reverse",
      },
    });
  }, []);


  return (
    <div
  className="relative container px-2 mt-0 md:mt-0 md:px-8 md:pt-5 flex flex-col min-h-screen justify-center items-center  mx-auto"
  id="hero"
  ref={ref1}
>
  <div className="absolute top-0 left-0 w-full h-full bg-primary-color opacity-10"></div>
  <div className="flex flex-col gap-6 md:gap-0 md:flex-col lg:flex-row px-2 md:px-5 lg:px-0  mt-16 md:mt-0" ref={ref2}>
    <div className="px-3 md:px-0 flex flex-1 flex-col justify-center items-center left-0 relative md:left-1 lg:left-5 " ref={ref3}>
      <h1 className="text-[5rem] leading-[1] md:text-[90px] md:leading-none text-secondary-color font-nueu">
        The ultimate <br />{" "}
        <span className="text-primary-color">SME Business</span> Bootcamp
      </h1>
      <p className="text-base md:text-lg lg:text-base ml-0 md:ml-[-0px] text-secondary-color mt-4 leading-normal w-full md:w-full">
        The journey of transformation at our interactive SME scale-up workshop, where you will dive into case studies, simulations, and group activities.
        Explore all aspects of business and financing opportunities, and learn how to scale up your business.
      </p>
      <div className="flex gap-4 mt-4  w-full ">
        <Link
          to="/sessions"
          className="bg-primary-color text-white py-3 px-6 rounded-lg hover:bg-secondary-color transition-all duration-300 ease-in-out"
        >
          Register Now
        </Link>
      </div>
    </div>
    <div className="flex flex-col gap-8 right-0 relative md:mt-5  lg:right-5" ref={ref4}>
      <div className="flex gap-3 md:gap-14">
        <div className="w-[180px] h-[120px] md:w-[500px] md:h-[200px] bg-secondary-color box-1 flex justify-center items-center">
          <img
            src={logo2}
            alt=""
            className="w-16 md:w-44 md:h-32 opacity-5"
          />
        </div>
        <div className="w-[180px] h-[120px] md:w-[200px] md:h-[200px] box-1-1"></div>
      </div>
      <div className="flex gap-3 md:gap-14 justify-end md:flex-row flex-row-reverse">
        <div className="w-[180px] h-[120px] md:w-[200px] md:h-[200px] bg-primary-color flex justify-center items-center">
          <img
            src={logo1}
            alt=""
            className=" w-16 md:w-32 md:h-32 object-contain opacity-10"
          />
        </div>
        <div className="w-[180px] h-[120px] md:w-[200px] md:h-[200px] bg-primary-color box-2"></div>
      </div>
      <div className="flex gap-3 md:gap-14">
        <div className="w-[180px] h-[120px] md:w-[200px] md:h-[200px] bg-secondary-color rounded-full flex justify-center items-center">
          <img
            src={logo3}
            alt=""
            className="w-16 md:w-44 md:h-32 opacity-10"
          />
        </div>
        <div className="w-[180px] h-[120px] md:w-[500px] md:h-[200px] bg-primary-color box-4"></div>
      </div>
    </div>
  </div>
</div>
  );
};

export default Hero;
