import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import Submit from "../components/Submit";
import { motion } from "framer-motion";
import { BsArrowBarLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Form = () => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const nextStep = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleBack = () => {
    navigate("/sessions");
  };

  const steps = [
    <Step1 nextStep={nextStep} prevStep={prevStep} />,
    <Step2 nextStep={nextStep} prevStep={prevStep} />,
    <Step3 nextStep={nextStep} prevStep={prevStep} />,
    <Submit  />,
  ];

  return (
    <div className="w-full min-h-screen form-container flex flex-col relative">
      <Navbar />
      <div className="w-11/12 mx-auto mt-8">
        <button
          className="text-primary-color font-nueu text-xl rounded-md flex items-center gap-2"
          onClick={handleBack}
        >
          <BsArrowBarLeft className="text-primary-color" />
          Back to Sessions
        </button>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="w-full h-full"
      >
        {steps[step]}
      </motion.div>
    </div>
  );
};

export default Form;