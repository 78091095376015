import React, { useState, useEffect } from "react";
import logo from "../assets/sme-logo.png";
import { CgMenu } from "react-icons/cg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = [
    "navbar",
    "w-full",
    "md:px-8",
    "z-50",
    "top-0",
    "shadow-sm",
    "md:shadow-none",
    "initialClass",
  ];
  if (scrolled) {
    navbarClasses.push("scrolledClass");
  }

  const links = [
    { to: "/", text: "Home", className: "text-[#7495A6] hover:text-gray-900" },
    {
      to: "/sessions",
      text: "Sessions",
      className: "text-[#7495A6] hover:text-gray-900",
    },
    {
      to: "/gallery",
      text: "Gallery",
      className: "text-[#7495A6] hover:text-gray-900",
    },
    // add more links as needed
  ];

  const activeLink = "text-red-900";

  return (
    <nav
      className={`navbar w-full md:px-8 z-50 top-0  bg-primary-color bg-opacity-10  md:shadow-none initialClass ${
        scrolled ? "scrolledClass" : ""
      }`}
    >
      <div className="flex w-full justify-between items-center  px-4 py-2">
        <div className="">
          <NavLink
            to="/"
            className=" font-nueu text-2xl text-primary-color font-semibold "
            activeClassName="active"
          >
            <img
              src={logo}
              alt="logo"
              className="w-24 h-auto md:w-32 md:h-auto "
            />
            {/* The SME Scale-Up */}
          </NavLink>
        </div>
        <div className="mx-6 hidden md:block">
          <ul className="flex gap-16 justify-between items-center w-full font-sans">
            {links.map((link, index) => (
              <li key={index}>
                <NavLink
                  to={link.to}
                  style={({ isActive }) => ({
                    color: isActive ? "#23D26B" : "#3c3c3c",
                  })}
                >
                  {link.text}
                </NavLink>
              </li>
            ))}
            {/* register button */}
            <li className="">
              <NavLink to="/sessions">
                <button className="bg-primary-color text-white px-8 py-2 rounded-sm font-nueu text-xl">
                  Book A Session
                </button>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="block md:hidden">
          <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <CgMenu className="text-4xl text-secondary-color" />
          </button>
        </div>
      </div>
      {/* mobile menu slide in from the right */}
      <div
        className={` bg-secondary-color w-full h-screen fixed top-0 right-0 z-50 transition-all duration-500 ease-in-out ${
          showMobileMenu ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {/* close button */}
        <div className="flex justify-end px-4 py-4">
          <button onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <IoCloseCircleOutline className="text-2xl text-white" />
          </button>
        </div>

        <ul className="flex flex-col gap-8 justify-center items-center w-full font-sans mt-20">
          {links.map((link, index) => (
            <li key={index}>
              <NavLink
                to={link.to}
                className={link.className}
                activeClassName={activeLink}
                style={({ isActive }) => ({
                    color: isActive ? "#23D26B" : "#f5f5f5",
                  })}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
          <li className="">
            <NavLink to="register" activeClassName={activeLink}>
              <button className="bg-primary-color text-white px-8 py-2 rounded-sm font-nueu text-xl">
                register now
              </button>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
