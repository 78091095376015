import React from "react";
import logo from "../assets/sme-logo.png";
import moment from "moment";

const InitialModal = ({ data, handleShowLogin }) => {
  const year = new Date().getFullYear();
  if (
    !data.firstName &&
    !data.lastName &&
    !data.sessionName &&
    !data.sessionLocation &&
    !data.seatNumber &&
    data.sessionId === 0 &&
    data.sessionDate === "0001-01-01T00:00:00" &&
    !data.paymentReceived &&
    data.admissionId === 0 &&
    data.admissionDate === "0001-01-01T00:00:00" &&
    !data.justAdmitted &&
    !data.valid &&
    !data.message
  ) {
    return <div className="h-full flex justify-center items-center">
      <h1 className="text-3xl">Invalid Admission Ticket</h1>
    </div>; 
  }

  


  return (
    <>
      <div className="p-3 h-[10vh] flex w-full justify-between">
        <img src={logo} alt="logo" className="w-[70px] h-[50px]" />
        <button
          className="w-max bg-primary-yellow hover:bg-yellow-500 text-white text-base font-bold py-2 px-6 rounded"
          onClick={handleShowLogin}
        >
          Login
        </button>
      </div>
      <div className="flex w-full justify-center h-[90vh] mt-5 px-1 md:px-3">
        <div className="flex flex-col items-center  ">
          <div className="flex flex-col gap-1">
            <p className="text-2xl md:text-4xl font-nueu  text-center px-2 text-primary-color">
              Welcome to SME Scale-Up {year}
            </p>
            <p className="text-base font-medium text-center px-2">
              {
                data?.valid && (
                  <span className="">This a valid admission ticket for one</span>
                ) 
              }
             
            </p>
          </div>
    
          <div className="w-full py-4 md:py-8 md:px-6 px-2 flex flex-col gap-4 mt-8 border-dashed border-2 rounded-md border-primary-yellow ">
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu">Name:</span> {data.firstName}{" "}
              {data.lastName}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu">Session:</span> {data.sessionName}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu">Location:</span> {data.sessionLocation}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu">Date:</span>{" "}
              {moment(data.sessionDate).format("Do MMMM YYYY")}
            </p>
            <p className="text-base font-medium px-2">
              <span className="mr-2 font-nueu">Time:</span>{" "}
              {moment(data.sessionDate).format("hh:mm A")}
            </p>
          </div>
           
              <p className=" mt-4 font-medium px-0 md:px-2 text-red-600 text-center text-sm md:text-xl">
              {/* <span className="text-red-600">Note:</span>    {data?.message} */}
              </p>
        </div>
      </div>
    </>
  );
};

export default InitialModal;
