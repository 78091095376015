import React, { useContext, useState, useCallback, useMemo} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormContext from "../FormContext";
import Input from "./Input";
import StepIndicator from "./StepIndicator";
import { baseUrl } from "../baseUrl";
import axios from "axios";
import Modal from "./Modal";
import Submit from "./Submit";

const Step3 = ({ nextStep, prevStep }) => {
  const { formData, setFormData, data } = useContext(FormContext);
  const [error, setError] = useState("");

  const [state, setState] = useState({
    error: "",
    loading: false,
    submitLoading: false,
    promoCode: { valid: null, error: "" },
    showConfirmationModal: false,
    showSuccessModal: false,
    postResponse: null,
  });

  const updateState = (updates) => setState((prev) => ({ ...prev, ...updates }));

  const validationSchema = Yup.object({
    aspirations: Yup.string()
      .trim()
      .max(250, "Must be 250 characters or less")
      .required("Aspirations is required"),
    growth: Yup.string()
      .trim()
      .max(250, "Must be 250 characters or less")
      .required("Growth is required"),
    expectations: Yup.string()
      .trim()
      .max(250, "Must be 250 characters or less")
      .required("Expectations are required"),
    hearAboutUs: Yup.string().required("How did you hear about us is required"),
  hearAboutUsOthers: Yup.string(),
    promoApplied: Yup.string().trim(),
  });

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (values.promoApplied && !state.promoCode.valid) {
        updateState({
          showConfirmationModal: false,
          error: "Invalid promo code. Please clear or correct it before submitting.",
          promoCode: { valid: false, error: "" },
        });
        return;
      }
      updateState({ submitLoading: true });

      try {
        const response = await axios.post(
          `${baseUrl}Registration/SaveRegistration`,
          { ...values, session: localStorage.getItem("sessionId") },
          { headers: { "Content-Type": "application/json" } }
        );

        updateState({
          submitLoading: false,
          postResponse: response.data,
          showSuccessModal: true,
          showConfirmationModal: false,
        });
        setFormData({});
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Submission failed. Please try again.";
        updateState({ submitLoading: false, error: errorMessage, showConfirmationModal: false });
      }
    },
  });

  const debounceValidation = useMemo(
    () => (fn, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn(...args), delay);
      };
    },
    []
  );
  
  const validatePromoCode = useCallback(
    debounceValidation(async (promoCode) => {
      if (!promoCode || promoCode.trim().length <= 4) {
        updateState({ promoCode: { valid: null, error: "" }, error: "" });
        return;
      }
  
      updateState({ loading: true });
      try {
        const response = await axios.get(
          `${baseUrl}Registration/CheckPromoCode/${formData.session}/${promoCode.trim()}`
        );
  
        if (response.data.status && response.data.data.slotAvailable) {
          updateState({ promoCode: { valid: true, error: "" } });
        } else {
          updateState({
            promoCode: { valid: false, error: response.data.message || "Invalid promo code." },
          });
        }
      } catch {
        updateState({
          promoCode: { valid: false, error: "Promo code validation failed." },
        });
      } finally {
        updateState({ loading: false });
      }
    }, 500),
    [formData.session]
  );
  
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      formik.handleChange(e);
      setFormData((prev) => ({ ...prev, [name]: value }));

      if (name === "promoApplied") {
        if (!value.trim()) {
          updateState({ promoCode: { valid: null, error: "" }, error: "" });
        } else {
          validatePromoCode(value.trim());
        }
      }
    },
    [validatePromoCode, formik, setFormData]
  );



  return (
    <>
      {
        !state.showSuccessModal ? (
          <>

            <form className="step-1-container" onSubmit={(e) => {
              e.preventDefault();
              updateState({ showConfirmationModal: true });
            }}>
              <div className="top">
                <StepIndicator currentStep={2} />
              </div>
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-secondary-color font-nueu text-xl">General Information</h1>
                <p className="text-sm text-slate-700">Please fill in all fields</p>
              </div>
              <div className="form-content flex flex-col gap-5 mt-4 w-11/12">
                {/* Form Fields */}
                <Input
                  label="What future aspirations do you have for your business? (Max 250 words)"
                  type="textarea"
                  name="aspirations"
                  value={formik.values.aspirations}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.aspirations && formik.errors.aspirations}
                />
                <Input
                  label="What’s stopping your business from being 10 times what it currently is? (Max 250 words)"
                  type="textarea"
                  name="growth"
                  value={formik.values.growth}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.growth && formik.errors.growth}
                />
                <Input
                  label="What are your expectations from this program? (Max 250 words)"
                  type="textarea"
                  name="expectations"
                  value={formik.values.expectations}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.expectations && formik.errors.expectations}
                />
                <Input
                  label="How did you hear about us"
                  type="select"
                  name="hearAboutUs"
                  value={formik.values.hearAboutUs}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.hearAboutUs && formik.errors.hearAboutUs}
                  options={
                    data && data.hearAboutUs
                      ? data.hearAboutUs.map((item) => ({
                        value: item.code,
                        label: item.description,
                      }))
                      : []
                  }
                />
                {formik.values.hearAboutUs === "10" && (
                  <Input
                    label="Please specify"
                    type="text"
                    name="hearAboutUsOthers"
                    value={formik.values.hearAboutUsOthers}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.hearAboutUsOthers && formik.errors.hearAboutUsOthers}
                  />
                )}


                <div className="flex flex-col gap-1 w-full">
                  <label className={`input-field text-sm ${error ? "text-red-500" : "text-gray-600"}`}>
                    Promo Code or Referral Code (if any)
                  </label>

                  <div className={`relative bg-[#fff] flex items-center input-field border px-2 py-3 rounded-md ${formik.touched.promoApplied && formik.errors.promoApplied ? "border-red-500" : "border-gray-400"} `}>
                    <input
                      type="text"
                      name="promoApplied"
                      value={formik.values.promoApplied}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Promo Code"
                      className="w-full bg-transparent focus:outline-none"
                    />
                    {state.loading && (
                      <div className="absolute right-2">
                        <svg
                          className="animate-spin h-5 w-5 text-primary-color"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </div>
                  {state.promoCode.error && <p className="text-sm text-red-500">{state.promoCode.error}</p>}
                </div>
                {state.promoCode.valid && <p className="text-sm text-green-500">Promo Code Applied</p>}
                {state.error && <p className="text-sm text-red-500 mt-2 ">{state.error}</p>}
              </div>

              <div className="button-container mt-4">
                <div className="flex gap-4 w-full">
                  <button type="button" onClick={prevStep} className="prev-btn font-nueu">
                    Prev
                  </button>
                  <button type="submit" disabled={formik.isSubmitting || state.submitLoading} className="next-btn font-nueu">
                    Submit & Pay
                  </button>
                </div>
              </div>
            </form>
            {state.showConfirmationModal && (
              <Modal
                title="Confirm Submission"
                onConfirm={formik.handleSubmit}
                onCancel={() => updateState({ showConfirmationModal: false })}
                confirmText="Yes, Submit"
                cancelText="Cancel"
                loading={state.submitLoading}
              >
                <p>Please confirm you would like to submit.</p>
              </Modal>
            )}
          </>
        ) : (
          <Submit response={state.postResponse} onClose={() => updateState({ showSuccessModal: false })} />
        )}
    </>
  );
};

export default Step3;